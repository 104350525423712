<template>
    <div>
      <h1>About</h1>
      <p>Esta es la página de "Acerca de".</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'AboutPage'
  };
  </script>
  