<template>
 <div>

<h1>My Skills</h1>
<a href="https://giovannyrojano.site/resume.html">
<h2 class="subtitle">My Resume is here</h2>
</a>
 </div>
  </template>
  
  <script>
  export default {
    name: 'SkillsPage'
  };
  </script>
 
  <style scoped >

*{
  color: #f1e4e8; 
}

 </style>   
  