import { createApp } from 'vue'

import App from './App.vue'

import index from './Index.vue';

import router from './router'; // Importa el archivo del enrutador
import '../src/assets/styles/style.scss'


createApp(index).use(router).mount('#app')
//createApp(App).mount('#app')
//createApp(App).use(router).mount('#app')


