<template>
  <!--
    <nav>
        <router-link to="/home">Inicio</router-link>
        <router-link to="/about">Acerca de</router-link>
    </nav>
    <router-view></router-view>  Donde se renderizan las rutas -->
  <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->

  <nav id="mainnav" class="">
    <ul class="dotnav dotnav-vertical dotnav-right nav">
      <li class="awesome-tooltip" title="Home"><a href="#home" class="effect-hover"></a></li>
      <li class="awesome-tooltip" title="About"><a href="#about" class="effect-hover"></a></li>
      <li class="awesome-tooltip" title="Skills"><a href="#skills" class="effect-hover"></a></li>
      <li class="awesome-tooltip" title="Projects"><a href="#projects" class="effect-hover"></a></li>
      <li class="awesome-tooltip" title="Contact"><a href="#contact" class="effect-hover"></a></li>
    </ul>
  </nav>
  <section id="home">
    <div class="card">
      <div class="left-section">
        <h1>Welcome to giovannyrojano.site</h1>
        <h2>Giovanny Rojano C.</h2>
        <p class="title">Software Engineer</p>
        <p>(+57) 3013754845</p>
        <p>giovanny04rojano@gmail.com</p>
      </div>
      <div class="right-section">
        <!-- Abstract shapes here -->
      </div>
    </div>
  <div style="height:8%">

  </div>
    <div style=" margin-left:10%;display:flex;    ">
      <div style="color: white; font-size: 1.5rem;width:60%; text-align: center;">
      Scalable software solutions, robust integrations, detailed reporting, and expert support for legacy code.
      </div>

    </div>
  <div style="height:5%">
        </div>
    <div  style="padding-right: 11%">
                <div style="color: white; font-size: 1.5rem;width:100%;       text-align: right; /* Alinea el texto a la derecha */   direction: ltr; margin-right:10%">
     Expertise in Continuous Improvement, System Architecture, Design, and Engineering.

      </div>
    </div>

    <!--
        <div
      class="boder-test parent"
      style="
        width: 80%;
        height: 40%;
        display: flex;
        margin: 0 auto;
        grid-template-columns: 70% 30%;
        grid-template-columns: 100%; /* Hijos ocupan el 100% del ancho */
        grid-template-rows: 70% 30%; /* Proporciones de altura 70% y 30% */
      "
    >
      <div class="boder-test child-70" style="text-align: center">HOLA</div>
      <div class="boder-test child-30" style="text-align: center">HOLA 2</div>
    </div>

   -->
  </section>
  <section id="about" style="background-color: #e2dcde">
   <AboutPage/>
  </section>
  <section id="skills" style="background-color: #2d2d34">
  <SkillsPage/>

  </section>
  <section id="projects"></section>
  <section id="contact"></section>
</template>

<script>

import AboutPage from './components/About/AboutPage.vue'
import SkillsPage from './components/Skills/SkillsPage.vue';

    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:5152877,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
document.addEventListener("DOMContentLoaded", function () {
  // Scrollspy functionality
  const sections = document.querySelectorAll("section");
  const navLinks = document.querySelectorAll("#mainnav a");

  window.addEventListener("scroll", function () {
    let current = "";

    sections.forEach((section) => {
      const sectionTop = section.offsetTop;
      const sectionHeight = section.clientHeight;

      // Check if the section is in view
      if (
        pageYOffset >= sectionTop - sectionHeight / 3 &&
        pageYOffset < sectionTop + sectionHeight - sectionHeight / 3
      ) {
        current = section.getAttribute("id");
      }
      console.log(current);
    });

    // Remove 'active' class from all links and add to the current section's link
    navLinks.forEach((link) => {
      link.classList.remove("active");
      console.log(link.getAttribute("href"));

      console.log(link.getAttribute("href").includes(current));

      if (link.getAttribute("href").includes(current)) {
        link.classList.add("active");
      }
    });
  });

  // Smooth scrolling functionality
  document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
    anchor.addEventListener("click", function (e) {
      e.preventDefault();

      // Get the target element by ID
      const targetId = this.getAttribute("href").slice(1);
      const target = document.getElementById(targetId);

      // Check if the target exists and scroll to it
      if (target) {
        const offset = 0; // Adjust this offset as needed for fixed headers, etc.
        window.scrollTo({
          top: target.offsetTop - offset,
          behavior: "smooth",
        });
      }
    });
  });
});

export default {
  name: "index",
  components: {AboutPage,SkillsPage},
  metaInfo:{
        title: 'Default Title',
  }
};
</script>

<style>
#index {
}

section {
  height: 1000px;
}
</style>