<template>
    <div class="bio-container">
    <h1>About me</h1>

    <div class="bio">
 
        
        <div class="bio-text">
            <p>Costeño del Magdalena, cercano de la tierra de Garcia Marquez, Inspirado de conocer las historias del mundo, Ingeniero de Sistemas y amante de la naturaleza.</p>
                 <p>nacido en santa marta, tierra del pibe y falcao</p>
                     <p>Criado en tierra de Sofia Vergara</p>
               <p>Graduado profesional en la Universidad Simon Bolivar en Barranquilla, tierra de Shakira.</p>
                 <p> Actualmente laborando en Cali, tierra de Salsa.</p>

                 <p> Dispuesto a Laborar presencialmente en Cali, Barranquilla, Medellin, Cartagena, Santa Marta. </p>

                 </div>
    </div>
</div>
  </template>
  
  <script>
  export default {
    name: 'AboutPage'
  };
  </script>
 
  <style scoped >

    .bio-container {
            max-width: 800px;
            margin: 0 auto;
            padding: 20px;
            background-color: #f9f9f9;
            border-radius: 10px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        }
        h1 {
            text-align: center;
            margin-bottom: 20px;
            font-size: 2em;
            color: #333;
        }
        .bio {
            display: flex;
            flex-direction: row;
            gap: 20px;
        }
        .bio img {
            max-width: 250px;
            border-radius: 10px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }
        .bio-text {
            flex-grow: 1;
        }
        .bio-text ul {
            list-style-type: disc;
            padding-left: 20px;
        }
        .bio-text ul li {
            margin-bottom: 10px;
        }
        .bio-text p {
            margin-bottom: 15px;
            color: #555;
            font-weight: 601;
        }
 </style>   
  